@use '@angular/material' as mat;
@mixin udl-details-theme($theme) {
  $primary:       map-get($theme, primary);
  $accent:        map-get($theme, accent);
  $warn:          map-get($theme, warn);
  $background:    map-get($theme, background);
  $foreground:    map-get($theme, foreground);

  h3 {
    padding-top: 0.625rem;
  }

  a {
    color: mat.get-color-from-palette($accent, 300);
    text-decoration: none;
  }

  .details-layout {
    height: 100%;
    background-color: mat.get-color-from-palette($primary, 100);
  }

  .details-container {
    background-color: mat.get-color-from-palette($primary, 100);
    height: 100%;
    padding: 1.5rem;
    width: 450px;
    box-shadow: 0 0 6px 0 grey;
  }

  .udl-driver-logs {
    padding: 24px 40px 30px;
  }

  .back-icon {
    .back {
      padding-left: 8px;
    }
  }
}

.assign-button {
  float: right;
  font-size: 16px;
}

.udl-map-container {
  width: 100%;
}

.udl-details-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
