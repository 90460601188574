@mixin udl-assigned-modal-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  h3 {
    font-size: 24px;
  }

  .udl-button {
    margin-left: 12px;
  }

  .float-right {
    float: right;
  }

  .text {
    padding-top: 12px;
    font-size: 14px;
  }
}
