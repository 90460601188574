@use '@angular/material' as mat;
@mixin udl-data-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .details-box {
    padding: 10px;
    width: 150px;
  }

  .details-title {
    @include mat.typography-level($zonar-default-typography, body-1);
    color: mat.get-color-from-palette($primary, 800);
    word-wrap: normal;
    padding-bottom: 3px;
    font-size: 14px;
  }

  .details-content {
    @include mat.typography-level($zonar-default-typography, body-1);
    color: mat.get-color-from-palette($primary, 900);
    word-wrap: normal;
    font-size: 16px;
    font-weight: bold;
  }
}
