@use '@angular/material' as mat;
@mixin udl-bulk-assign-theme($theme) {
  $primary:       map-get($theme, primary);
  $accent:        map-get($theme, accent);
  $warn:          map-get($theme, warn);
  $background:    map-get($theme, background);
  $foreground:    map-get($theme, foreground);

  .details-layout {
    height: 100%;
  }

  .udl-info {
    padding: 10px 0;
  }

  .date-info {
    padding-left: 10px;
  }

  .bulk-assign-title-row {
    width: 400px;
    padding: 10px 15px 0;
  }

  .data {
    padding-top: 5px;
    font-size: 16px;
    font-weight: bold;
  }

  .udl-title {
    font-size: 14px;
  }

  mat-list-item {
    display: flex;
    flex-direction: column;
  }

  .mat-list-item-content {
    display: flex;
    flex-direction: column;
  }


  .bulk-assign-info {
    background-color: mat.get-color-from-palette($primary, 100);
    box-shadow: inset 0px 0px 6px 0px grey;
    width: 450px;
  }

  .upper-items {
    padding: 24px 20px 0;
  }


  .bulk-assign-button {
    height: 35px;
    margin-top: 10px;
    font-size: 14px;
    margin-right: 20px;
  }

  .bulk-assign-container {
    background-color: mat.get-color-from-palette($primary, 100);
    width: 100%;
    overflow-y: auto;
  }

  .trip-details {
    padding-left: 40px;
  }

  .mat-list-base .mat-list-item, .mat-list-base .mat-list-option {
    padding: 10px 0;
  }

  mat-list-option[aria-selected="true"] {
    background-color: rgba(0,117,180, 0.1);
  }

  .mat-list-option:hover {
    background-color: rgba(0,117,180, 0.3);
  }
  .mat-list-option:focus {
    background-color: rgba(0,117,180, 0.1);
  }

  .udl-bulk-navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }
}

