@import './variables.scss';
@import './mixins.scss';
@import './theme.scss';

html,
body {
    height: 100%;
}

body {
    margin: 0;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-button-wrapper {
    padding: 0;
    margin: auto;
    min-width: auto;
    line-height: unset;
}

// Progress spinner on app-root component
.app-root-progress-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

// UDL list table css to show background color when user hovers on a row and also adds background color to expanded details
.udl-list {
    .table-container {
        .mat-table {
            .mat-row {

                &:hover,
                &.expandable-row {
                    background-color: #e5f1f6;
                }

                &:not(.expandable-row):hover {
                    cursor: pointer;
                }
            }
        }
    }
}

// When preview all selected, then show active background on all udls
.bulk-assign-udls.all-selected .mat-list-option {
    background-color: rgba(0, 117, 180, 0.1);
}

.preview-all-tooltip {
    white-space: pre-line;
}

/** Select dropdown custom styles **/
@mixin custom-select-panel-css($width) {
    .cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
        width: $width;
    }
}

.default-width-select-panel {
    min-width: $default-dropdown-field-width !important;
    width: $default-dropdown-field-width;
    margin-left: 2em;
    @include custom-select-panel-css($default-dropdown-field-width);
}

.driver-dropdown-select-panel {
    @include custom-select-panel-css($default-dropdown-field-width);
}

/** map and logs preview links used on udl detail and bulk udl assign components **/
.preview-links {
    padding-top: 24px;
    padding-right: 24px;
    z-index: 1;
    position: absolute;
    top: 130px;
    right: 24px;

    a {
        border-radius: 18px;
        font-size: 16px;
        border-color: #A5AAAF;

        .mat-button-wrapper {
            font-weight: normal;
            color: #292929;

        }

        &.active-link {
            border: 2px solid #0175b4;
        }
    }
}

// Common class for page content wrapper, same styles should used on udl list also
.page-content-wrapper {
    padding: 24px;
}

// NDR List page styles
.ndr-list.mat-list-base {
    .mat-list-item {
        .mat-list-item-content {
            @include set-padding-right-left($ndr-mat-list-padding-right-left);
        }
    }
}

/***** Driver logs CSS ******/
// unit-toggle
.unit-toggle {
    .card_title {
        color: #292929;
        font-family: "Open Sans";
        font-weight: bold;
        font-size: 14px;
        padding-right: 0.5em;
    }
    .mat-slide-toggle .mat-slide-toggle-bar {
        background-color: #D0D3D5;
    }
    .mat-slide-toggle .mat-slide-toggle-thumb {
        background-color: #5B6770;
    }
}

.driver-logs-section {
    .current-setup {
        .mat-tab-group .mat-tab-header {
            border-bottom-color: #A2AAAF;
        }
    }
}
// info-icon
.info-icon {
    color: #5B6770 !important;
}

// Overlay css when sidebar opens
.sidebar-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    user-select: none;
    animation-name: modal-in;
    animation-duration: 0.4s;
    animation-timing-function: cubic-bezier(0.05, 0.74, 0.2, 0.99);
    animation-fill-mode: forwards;
    background: rgba(0, 0, 0, 0.4);
}

.edit-dialog-content {
    .mat-form-field-subscript-wrapper {
        margin-top: 0;
    }
}

// This is to remove the arrow icons on drivers/no-driver-reason tabbed search filter.
.tab-search-results {
    .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
        display: none !important;
    }
}

/* 
    Workaround fix, mat paginator page page size select width is overridden by 
    zonar-ui-core/projects/core/src/lib/zonar-ui-brand/components/change-company/change-company.component.scss
    .mat-form-field {
        width: 100%;
    }
    To fix this we need a pattern library change and wrap this css to some parent class, instead of updating directly mat-form-field styles.
*/
@media only screen and (max-width: 600px) {
    .mat-form-field {
        width: 100%;
    }
}


.hos-table-container {
    .mat-paginator-page-size-select {
        // This width is the actual width from angular material paginator
        width: 56px;
    }
}
.brand-footer {
    font-size: 14px;
}
.header-logo-text {
    letter-spacing: 0.01rem !important;
}

//radio buttons in export modal only
.export-modal{
    .mat-radio-outer-circle{
        border-color: #0175b4 !important;
    }
}
//update the input and dropdown fields in the driver -filter component
@media only screen and (max-width: 768px)  {
    .dropdown-filter-mobile {
        mat-form-field {
            width: 100%;
        }
    }
    .default-width-select-panel {
        min-width: 360px !important;
        width: 360px;
    }
}
