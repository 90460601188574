@mixin pill-filter-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-button-toggle-group-appearance-standard {
    border: none;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 30px;
  }

  .mat-button-toggle {
    border-radius: 18px;
    margin: 8px;
    border: solid 2px #A5AAAF;
  }

  .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle + .mat-button-toggle {
    border: solid 2px #A5AAAF;
  }
}
