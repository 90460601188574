@mixin zui-page-not-found-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .pnf-container {
    padding: 60px;
  }

  .pnf-info {
    border-left: solid #D8D8D8 1px;
    margin-top: 50px;
  }

  .pnf-line {
    padding-left: 14px;
  }

  .pnf-paragraph {
    width: 800px;
    margin-bottom: 30px;
    font-size: 20px;
    margin-top: 20px;
  }

  .pnf-title {
    font-size: 45px;
    font-weight: 600;
  }

  .pnf-logout {
    margin-left: 20px;
  }
}
