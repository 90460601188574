// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$UDL-app-primary: mat.define-palette(mat.$indigo-palette);
$UDL-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$UDL-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$UDL-app-theme: mat.define-light-theme((
  color: (
    primary: $UDL-app-primary,
    accent: $UDL-app-accent,
    warn: $UDL-app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($UDL-app-theme);

@import "../../node_modules/@zonar-ui/material/zonar-ui-material.theme";
@import "../../node_modules/@zonar-ui/core/zonar-ui-core.theme";
// @import "~@zonar-ui/progress-spinner/zonar-ui-progress-spinner.theme";
@import '../app/modules/udl/udl-detail/udl-detail-layout.component';
@import "../../src/app/modules/udl/udl-list/filter-pill/filter-pill.component";
@import "../../node_modules/@zonar-ui/date-picker/zonar-ui-date-picker.theme";
@import "../../src/app/modules/udl/udl-detail/assigned-udl-modal/assigned-udl-modal.component";
@import "../../node_modules/@zonar-ui/notifications/zonar-ui-notifications.theme";
@import "../app/modules/zui-page-not-found/zui-page-not-found.component";
@import "../app/modules/udl/udl-bulk-assign/udl-detail-info/udl-detail-info.component";
@import "../app/modules/udl/udl-bulk-assign/udl-bulk-assign/udl-bulk-assign.component";
@import "../app/modules/udl/shared-components/udl-data/udl-data.component";
@import "../../node_modules/@zonar-ui/tabbed-search-bar/zonar-ui-tabbed-search-bar.theme";
@import "../../node_modules/@zonar-ui/hos-components/zonar-ui-hos-components.theme";
@import "../../node_modules/@zonar-ui/zonar-ui-map/zonar-ui-map.theme";
@import "../../node_modules/@zonar-ui/hos-navigation/zonar-ui-hos-navigation.theme";
@import "../../node_modules/@zonar-ui/hos-filters-container/zonar-ui-hos-filters-container.theme";
@import '../../node_modules/@zonar-ui/footer/zonar-ui-footer.theme.scss';
@import '../../node_modules/@zonar-ui/sidenav/zonar-ui-sidenav.theme.scss';
@import 'material-icons/iconfont/material-icons.scss';

// Progress spinner pattern library theme has compile issue with the latest zonar-ui material library. 
// So, instead of importing from there included the same mixin here.
@mixin zonar-ui-progress-spinner-theme($theme) {
  $primary:       map-get($theme, primary);
  $accent:        map-get($theme, accent);
  $warn:          map-get($theme, warn);
  $background:    map-get($theme, background);
  $foreground:    map-get($theme, foreground);

  .progress-spinner-container {
    .radar-fill {
        fill: get-mat-color($primary, default);

        &.accent {
            fill: get-mat-color($accent, default);
        }

        &.warn {
            fill: get-mat-color($warn, default);
        }
    }
  }
}
@mixin apply-zonar-theme($theme){
  @include mat.all-component-themes($theme);
  @include zonar-ui-core-theme($theme);
  @include zonar-ui-progress-spinner-theme($theme);
  @include zonar-ui-hos-components-theme($theme);
  @include udl-details-theme($theme);
  @include pill-filter-theme($theme);
  @include zonar-ui-date-picker-theme($theme);
  @include udl-assigned-modal-theme($theme);
  @include zonar-ui-notifications-theme($theme);
  @include zui-page-not-found-theme($theme);
  @include udl-bulk-assign-theme($theme);
  @include udl-data-theme($theme);
  @include zonar-ui-tabbed-search-bar-theme($theme);
  @include zonar-ui-map-theme($theme);
  @include zonar-ui-hos-navigation-theme($theme);
  @include zonar-ui-hos-filters-container-theme($theme);
  @include zonar-ui-footer-component-theme($theme);
  @include zonar-ui-sidenav-theme($theme);
}

.zonar-default-theme{
  @include apply-zonar-theme($zonar-default-theme);

  mat-calendar {
    border: 0;
  }

  .exportDriverLogsMenu {
    padding: 0;
  }
}

.zonar-dark-theme{
  @include  apply-zonar-theme($zonar-dark-theme);
  background-color: black;
}

.zonar-default-theme .mat-option .mat-option-text {
  font-size: 1rem;
}

// Fix for the zui-searchable-dropdown, desktop and mobile - the placeholder text should not overlap the search icon
// TODO - Move this into the pattern library
.cdk-overlay-pane {
  .mat-select-search-inner {
    .mat-select-search-input {
      text-indent: 1em;
    }
  }
}
